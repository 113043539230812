import "antd/dist/antd.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "./index.css";
import AppLayout from "./AppLayout";
import { BrowserRouter } from "react-router-dom";
import { RootProvider } from "./rootContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <RootProvider>
        <Provider store={store}>
          <AppLayout />
        </Provider>
      </RootProvider>
    </React.StrictMode>
  </BrowserRouter>
);
