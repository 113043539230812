import React, { useState } from "react";
import "antd/dist/antd.css";
import { Select } from "antd";
const Option = Select.Option;

export const SelectControl = (props) => {
  const [newOption, setNewOption] = useState("");

  const onChangeList1 = (value) => {
    if (value === 0) {
      //TODO: MakeApiCall & get new id
      this.setState({ isLoading: true });
      //LETS FAKE IT
      setTimeout(() => {
        this.setState({ list1Value: 0, isLoading: false });
      }, 1500);
    } else {
      this.setState({ list1Value: value });
    }
  };

  const list1SelectOptions = props.items.map((o) => (
    <Option key={o}>{o}</Option>
  ));
  return (
    <div>
      <label>{props.label}</label>
      <Select
        showSearch
        value={props.selectedItem}
        optionFilterProp="children"
        onChange={props.onSelectedItemChange}
        onSearch={(e) => {
          if (e) {
            setNewOption(e);
          }
        }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {newOption &&
          props.items &&
          props.items.filter((o) => o === newOption).length === 0 && (
            <Option key={newOption}>{newOption}</Option>
          )}
        {list1SelectOptions}
      </Select>
    </div>
  );
};
